/* global MatgenGlobal, $, API_ENV, bootstrap */

import * as DOMPurify from 'dompurify';
import SectionLoader from '../-components/objects/SectionLoader/index.js';
import loginModalContent from './data/login-modal.js';

export const studySelectContentLoading = () => {
  $('#study-select-content')
    .empty()
    .show()
    .append(
      $(`
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  `)
    );
};

export const createStudyForm = (target) => {
  $(target)
    .empty()
    .append(
      $(`
    <div class="mb-3" style="text-align:left;">
      <label id="study-name-label" for="study-name" class="form-label" required>Folder Name</label>
      <input type="text" class="study-select form-control" id="study-name" placeholder="Folder Name">
    </div>
    <div class="mb-3" style="text-align:left;">
      <label for="study-id" class="form-label">Study ID (optional)</label>
      <input type="text" class="study-select form-control" id="study-id" placeholder="Study ID">
    </div>
  `)
    );
};

export const studySelectElement = ({ studies, id, target } = {}) => {
  const selectEl = $(`
    <select id="${id}"></select>
  `);
  $(target).empty().append(selectEl);

  const exists = studies.some((subArray) => subArray['name'] === 'Default');

  if (!exists) {
    selectEl.append(
      '<option value="00000000-0000-0000-0000-000000000000">Default</option>'
    );
  }

  for (let i = 0; i < studies.length; i++) {
    selectEl.append(
      $(`
        <option value="${studies[i].id}">${studies[i].name}</option>
      `)
    );
  }
};

export const loadStudySelect = async (website = false) => {
  studySelectContentLoading();

  let studyFinal = [];

  if (website) {
    const studies = await MatgenGlobal.Data.API.request(`/user-folders?s=open`);

    const token = await MatgenGlobal.AuthUser.getSessionToken();

    const opts = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const website_data = await MatgenGlobal.Amplify.API.get(
      'authenticated',
      `/user-content?material_id=website`,
      opts
    );

    for (let i = 0; i < studies.length; i++) {
      const data = website_data.filter(
        (d) => d.user_folder_id === studies[i].id
      );
      if (!data.length) {
        studyFinal.push(studies[i]);
      }
    }
  } else {
    studyFinal = await MatgenGlobal.Data.API.request(`/user-folders?s=open`);
  }

  if (studyFinal && Array.isArray(studyFinal) && studyFinal.length > 0) {
    studySelectElement({
      studies: studyFinal,
      id: 'existing-studies-select-element',
      target: '#study-select-content',
    });
  } else if (studyFinal.length === 0) {
    studySelectElement({
      studies: studyFinal,
      id: 'existing-studies-select-element',
      target: '#study-select-content',
    });
  } else {
    $('#study-select-content').empty();
    MatgenGlobal.UI.handleError(
      'Error retrieving studies',
      'There was a problem finding your studies. Please try again later or contact support.'
    );
  }
};

export const studySelect = (template_id, grantNumber = '') => {
  const buttons = [
    {
      id: `study-select-cancel`,
      classname: '',
      label: 'Cancel',
    },
    {
      id: `study-select-continue`,
      attributes: [
        `data-template-id="${template_id}"`,
        `data-grant-number="${grantNumber}"`,
      ],
      classname: 'primary',
      label: 'Continue',
    },
  ];

  MatgenGlobal.M4CModal.show({
    id: `study-select-modal`,
    title: 'Where do you want to store this material?',
    content: `
    <form id="study-select-form" class="revised" style="display:block;">
      <div style="width: fit-content; margin: auto;"  id="existing-study-option-wrapper">
        <div class="d-flex">
          <input type="radio" id="select-existing-study" class="study-select" name="study-select" value="existing" checked>
          <label for="select-existing-study">Existing Folder</label>
        </div>
        <div class="d-flex">
          <input type="radio" id="select-new-study" class="study-select" name="study-select" value="new">
          <label for="select-new-study">New Folder</label>
        </div>
      </div>
      <div id="study-select-content"></div>
    </form>
    `,
    buttons,
  });
};

export const studyCreate = () => {
  const buttons = [
    {
      id: `study-create-cancel`,
      classname: '',
      label: 'Cancel',
    },
    {
      id: `study-create-continue`,
      classname: 'primary',
      label: 'Continue',
    },
  ];

  MatgenGlobal.M4CModal.show({
    id: `study-create-modal`,
    title: 'Name folder',
    content: `
    <form id="study-create-form" class="revised" style="display:block;">
      <div id="study-select-content"></div>
    </form>
    `,
    buttons,
  });
};

const answerExample = ({ example, i }) => {
  if (example.type === 'textarea') {
    return `
    <div class="carousel-item${i === 0 ? ' active' : ''}">
      <div class="example-text-slide">
        <h5 class="example-text-slide-header">Example Text ${i + 1}</h5>
        <div>${example.answer}</div>
      </div>
    </div>
    `;
  } else if (example.type === 'richtext') {
    let sanitized = false;
    try {
      const exampleAnswer = JSON.parse(example.answer);
      sanitized = DOMPurify.sanitize(exampleAnswer.html);
    } catch (e) {
      console.error(e, example.answer);
      return `
      <div class="carousel-item${i === 0 ? ' active' : ''}">
        <div class="example-text-slide">
          <h5 class="example-text-slide-header">Example Text ${i + 1}</h5>
          <div>Bad HTML</div>
        </div>
      </div>
      `;
    }
    if (sanitized) {
      return `
      <div class="carousel-item${i === 0 ? ' active' : ''}">
        <div class="example-text-slide">
          <h5 class="example-text-slide-header">Example Text ${i + 1}</h5>
          <div class="example-content">${sanitized}</div>
          <div class="use-example-wrapper">
            <button class="btn btn-outline-primary use-example">Use this text</button>
          </div>
        </div>
      </div>
      `;
    }
  }
};

export const richTextContainer = (text, subtext, required, form, id) => {
  return `
  <div class="blue-box richtext" tabindex="-1">
    <div>
      <div data-question-id="${id}" class="head-and-sub-centered${
    required ? ' required' : ''
  }">
        <h5>${text}</h5>
        <p>${subtext ? subtext : '&nbsp;'}</p>
      </div>
      ${form}
    </div>
  </div>
  `;
};

const carouselButtons = (id) => {
  return `
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#example-carousel-${id}"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true">
      <i class="fa-regular fa-angle-left fa-2xl"></i>
    </span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#example-carousel-${id}"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true">
      <i class="fa-regular fa-angle-right fa-2xl"></i>
    </span>
    <span class="visually-hidden">Next</span>
  </button>
  `;
};

const exampleCarousel = (
  q,
  header = true,
  ariaCurrentState = 0,
  activeState = 0
) => {
  return `
  ${header ? '<h4>Examples</h4>' : ''}
  <div id="example-carousel-${q.id}" class="carousel slide">
    <div class="carousel-inner">
      ${q.examples
        .map((a, i) =>
          answerExample({
            example: a,
            i,
          })
        )
        .join('')}
    </div>
    <div class="carousel-indicators">
      ${q.examples
        .map(
          (a, i) => `
        <button type="button" data-bs-target="#example-carousel-${
          q.id
        }" data-bs-slide-to="${i}" class="${
            activeState === 0 ? 'active' : ''
          } slide-control-btn" aria-current="${
            ariaCurrentState === 0 ? 'true' : 'false'
          }" aria-label="Slide ${i + 1}">&#x25CF;</button>
        `
        )
        .join('')}
    </div>
    ${carouselButtons(q.id)}
  `;
};

const richTextModalButtons = (q, loggedIn = false) => {
  return `
  <div id="rich-text-modal-buttons">
    <a id="cancel-rich-text" data-question-id="${
      q.id
    }" href="#" class="link-primary">Cancel</a>
    ${
      loggedIn
        ? `
    <button id="reuse-my-text" class="btn btn-outline-primary type="button" style="margin-left:1em;" data-question-text="${q.text}">
      Reuse my text
    </button>
    `
        : ''
    }
    <button id="rich-text-update" data-question-id="${q.id}" ${
    q.uc_id ? `data-uc-id="${q.uc_id}"` : ''
  } class="btn btn-primary rich-text-update" type="button" style="margin-left:1em;">
      Update
    </button>
  </div>
  `;
};

const richTextModalTabs = (q) => {
  return `
  <ul class="nav nav-tabs" id="rich-text-modal-tabs" role="tablist">
    <li class="nav-item" role="presentation">
      <a class="nav-link active" id="preview-tab" data-bs-toggle="tab" data-bs-target="#preview-tab-pane" href="#" role="tab" aria-controls="preview-tab-pane" aria-selected="true">Preview</a>
    </li>
    <li class="nav-item" role="presentation">
      <a class="nav-link" id="examples-tab" data-bs-toggle="tab" data-bs-target="#examples-tab-pane" href="#" role="tab" aria-controls="examples-tab-pane" aria-selected="false">Examples</a>
    </li>
  </ul>
  <div class="tab-content" id="rich-text-modal-content">
    <div class="tab-pane fade show active" id="preview-tab-pane" role="tabpanel" aria-labelledby="preview-tab" tabindex="0">
      <div id="preview-instructions">See what your text will look like as you type it so you can format it appropriately</div>
      <div id="rich-text-preview"></div>
    </div>
    <div class="tab-pane fade" id="examples-tab-pane" role="tabpanel" aria-labelledby="examples-tab" tabindex="0">
      <div class="tab-flex">
        ${q.examples ? exampleCarousel(q, false) : ''}
      </div>
    </div>
  </div>
  `;
};

export const richTextRight = (q, modal) => {
  if (modal) {
    return richTextModalTabs(q);
  }
  return `
  <div class="rich-text-right-inner" id="rich-text-preview-${q.id}">
    ${q.examples ? exampleCarousel(q) : ''}
    </div>
  </div>
  `;
};

export const RichTextForm = (q, modal = false, loggedIn = false) => {
  let instructions = false;
  try {
    if (q.instructions) {
      instructions = JSON.parse(q.instructions);
    }
  } catch (e) {
    instructions = false;
  }
  let cleanInstructions = false;
  if (instructions) {
    cleanInstructions = DOMPurify.sanitize(instructions.html, {
      ADD_ATTR: ['target'],
    });
  }
  if (q.examples) {
    q.examples.sort((a, b) => a.sort_order - b.sort_order);
  }
  return `
    <div id="form-container-${q.id}" style="height:100%;">
      <form
        id="rich-text-form-${q.id}"
        class="matgen-richtext-form"
        style="min-width:450px;height:100%;"
      >

        <div class="rich-text-wrapper">

          <div class="rich-text-half rich-text-editor-wrapper">
          <div class="rich-text-left-top">

            <h5>Consider Including:</h5>
            <div class="question-instructions" id="rich-text-instructions-${
              q.id
            }">
            ${cleanInstructions ? cleanInstructions : ''}
            </div>
          </div>

            <div id="quill-container"><div id="input-rich-text-${
              q.id
            }" data-lpignore="true"></div></div>
            ${modal ? richTextModalButtons(q, loggedIn) : ''}
          </div>

          <div class="rich-text-half rich-text-preview-wrapper">

            <div class="rich-text-right">
              ${richTextRight(q, modal)}
            </div>

          </div>
        </div>

      </form>
    </div>
  `;
};

export const mainContentLoader = ({
  empty = false,
  showMessage = false,
  target = MatgenGlobal.MainContentWrapper,
  addClass = false,
} = {}) => {
  console.error('MAIN LOAD START');
  if ($('#main-loader-target').length > 0) {
    //console.error('mainContentLoader DUPE');
    return false;
  }
  if (empty) {
    $(MatgenGlobal.MainContentContainer).empty();
  }
  /*
  $(MatgenGlobal.MainContentContainer).append(
    $(`
        <div id="preload-content-wrapper" style="background:#ffffff80;height:100%;max-height:80vh;width:100%;position:absolute;top:0;left:0;z-index:2500;">
          <div id="preload-content-inner">
            <div id="preload-content">
            </div>
          </div>
        </div>
      `)
  );*/

  $(target).append(
    $(`
      <div id="main-loader-wrapper">
        <div id="main-loader-target"></div>
      </div>
    `)
  );

  SectionLoader.displayLoader({
    transitionTargetSelector: MatgenGlobal.MainContentContainer,
    loaderTargetSelector: '#main-loader-target',
    loaderId: 'preload-content-loader',
    loaderAnimationMarkup: SectionLoader.blockLoaderAnimation(),
    showMessage,
  });
  if (addClass) {
    $('#main-loader-wrapper').addClass(addClass);
  }
};

export const mainContentLoaderStop = () => {
  console.error('MAIN LOAD STOP');
  $('#main-loader-wrapper').remove();
  $(MatgenGlobal.MainContentContainer).css('transition', 'initial');
  $(MatgenGlobal.MainContentContainer).css('filter', 'initial');
};

export const killModal = (selector) => {
  if ($(selector).length > 0) {
    const modal = bootstrap.Modal.getOrCreateInstance(selector);
    modal.hide();
    $(selector).remove();
    if ($('.modal-backdrop.fade.show').length > 1) {
      $('.modal-backdrop.fade.show').not(':first').remove();
    } else {
      $('.modal-backdrop.fade.show').remove();
    }
  }
};

window.killModal = killModal;

export const getHTML = ({
  id,
  title,
  top_content,
  content,
  buttons = [],
  header = true,
  footer = true,
  //closeX = true,
  width = 'fit-content',
  matgen = false,
} = {}) => {
  console.error('modal getHTML()');
  return `
  <div class="modal revised fade ${
    matgen ? 'm4c-matgen' : ''
  }" id="${id}" tabindex="-1" role="dialog" aria-labelledby="${id}-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content"  style="width:${width}">
        ${
          header
            ? `
          <div class="modal-header">
            ${
              title
                ? `<h4 class="modal-title" id="${id}-label">${title}</h4>`
                : ''
            }
            ${
              top_content
                ? `<p class="modal-subtitle" id="${id}-sub-label">${title}</p>`
                : ''
            }
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
        </div>
      `
            : ''
        }
      <div class="modal-body">
          ${
            !header
              ? '<button type="button" class="btn-close alert-modal-close-button" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
              : ''
          }
          ${content}
          </div>

          ${
            footer
              ? `
            <div class="modal-footer" style="position:relative;">
              <div class="input-group">
            ${buttons
              .map((button) => {
                const bclasses = button.classes || button.classname;
                const classes = bclasses.includes('primary')
                  ? 'button--blue'
                  : 'button--blue button--hollow';
                return `<button ${
                  button.attributes ? button.attributes.join(' ') : ''
                } id="${button.id}" type="button" class="${classes} ${
                  button.active ? 'active' : ''
                }" tabindex="0">${button.label}</button>`;
              })
              .join('')}
            </div>
            <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="position:absolute;top:4px;right:1em;border-radius: 5em !important;margin-right:-8px;padding: 0 5px;font-size:.9em;">Close</button>-->
            `
              : ''
          }
          </div>
        </div>
      </div>
    </div>
    `;
};

export const showModal = ({
  id,
  title,
  content,
  buttons = [],
  //classes = '',
  header = true,
  footer = true,
  //closeButton = false,
  //closeText = 'Close',
  //closeX = true,
  target = 'body',
  width = 'fit-content',
  matgen = false,
  noFrame = false,
  top_content = false,
  bodyClose = false,
  fade = true,
} = {}) => {
  const html = `
  <div class="modal revised ${fade ? 'fade' : ''} ${
    matgen ? 'm4c-matgen' : ''
  }" id="${id}" tabindex="-1" role="dialog" aria-labelledby="${id}-label" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content matgen-modal-content${
        noFrame ? ' no-frame' : ''
      }"  style="width:${width};">
        ${
          header
            ? `
              <div class="modal-header">
                  ${
                    title
                      ? `<h4 class="modal-title" id="${id}-label">${title}</h4>`
                      : ''
                  }
                     
                      ${
                        title ? '' : ''

                        /*
                        id === 'options-modal' &&
                        MatgenGlobal.AuthUser.getUserRole() === 'user'
                          ? '<p class="content-link">Content provided in OutreachPro is informed by audience research conducted by NIA (<a href="/audience-informed-materials/index-no-nav.html" target="_blank">learn more</a>&nbsp;<i class="fas fa-external-link" aria-hidden="true"></i>)</p>'
                          : ''
                        */
                      }
                 

                  ${
                    top_content
                      ? `<p class="modal-subtitle" id="${id}-sub-label">${title}</p>`
                      : ''
                  }
                  <div id="close-btn-wrapper">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
              </div>
            `
            : ''
        }
      <div class="modal-body matgen-modal-body">
      ${
        !header && bodyClose
          ? `
          <div id="close-btn-wrapper">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
      `
          : ''
      }
      ${content}
          </div>

          ${
            footer || buttons.length > 0
              ? `
            <div class="modal-footer" style="position:relative;">
              <div class="input-group">
            ${buttons
              .map((button) => {
                const bclasses = button.classes || button.classname;
                const classes = bclasses.includes('primary')
                  ? 'button--blue'
                  : 'button--blue button--hollow';
                return `<button ${
                  button.attributes ? button.attributes.join(' ') : ''
                } id="${button.id}" type="button" class="${classes} ${
                  button.active ? 'active' : ''
                }" tabindex="0">${button.label}</button>`;
              })
              .join('')}
            </div>
            <!--<button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="position:absolute;top:4px;right:1em;border-radius: 5em !important;margin-right:-8px;padding: 0 5px;font-size:.9em;">Close</button>-->
            `
              : ''
          }
          </div>
        </div>
      </div>
    </div>
    `;
  $(`#${id}`).remove();
  $(target).append($(html));
  const myModal = new bootstrap.Modal(document.getElementById(id));
  myModal.show();
  MatgenGlobal.modalOpen = true;

  $(document).on('hidden.bs.modal', (e) => {
    killModal(`#${$(e.target).attr('id')}`);
    $('body').css('overflow', 'initial');
  });
};

export const authUI = async () => {
  //console.error('AUTH UI');
  $('#user-manage').hide();
  $('#my-sites').hide();
  $('#my-mats').hide();
  $('#my-studies').hide();
  $('#user-dropmenu').hide();
  //$('#header-login').show();
  $('#study-login-links').show();

  $('#auth-header')
    .empty()
    .append(
      $(`
    <a
      href="#"
      id="header-login"
      class="login-modal-link header__nav__item login-nav"
      >Log In</a>
  `)
    );

  if (MatgenGlobal.AuthUser && MatgenGlobal.AuthUser.user) {
    //const groups = await MatgenGlobal.AuthUser.getUserGroups();

    $('#auth-header')
      .empty()
      .append(
        $(`
          <div class="dropdown">
            <a href="#" class="small-button--blue login" id="user-dropmenu" data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false" role="button">
            </a>
            <div id="nia-user-nav" class="dropdown-menu dropdown-menu-right dropdown-menu-end" aria-labelledby="user-dropmenu">

              <a id="my-mats" href="/my-materials.html" class="dropdown-item">
                My Materials
              </a>

              <button id="template-manage" class="dropdown-item" type="button">
                Templates
              </button>
              <button id="microsite-manage" class="dropdown-item" type="button">
                Websites
              </button>
              <button id="user-signout" class="dropdown-item" type="button">
                Log Out
              </button>
            </div>
          </div>
        `)
      );
    if ($('#user-email').length === 0) {
      $('#nia-user-nav').prepend(`
        <div id="user-email" class="dropdown-item text-primary disabled">${MatgenGlobal.AuthUser.user.attributes.email}</div>
      `);
    }

    //if (groups.includes('NIA-Microsite-Editor-Group')) {
    $('#existing-studies').show();
    //}

    if (API_ENV !== 'production') {
      $('#env-badge')
        .empty()
        .append(
          $(`
          <span class="badge badge-info" title="TENANT: ${MatgenGlobal.AMPLIFY_VARS.tenant}\nS3: ${MatgenGlobal.AMPLIFY_VARS.s3Bucket}\nCognito: ${MatgenGlobal.AMPLIFY_VARS.userPoolId}">
            ENV: ${API_ENV}
          </span>
        `)
        );
      $('#env-badge').show();
    }

    $('#user-dropmenu').text('My Account');
    $('#user-dropmenu').show();

    if (MatgenGlobal.AuthUser.getUserRole() === 'user') {
      $('#my-mats').show();
      $('#my-studies').show();
      //if (groups.includes('NIA-Microsite-Editor-Group')) {
      $('#my-sites').show();
      //}
    }
    $('#header-login').hide();
    $('#study-login-links').hide();

    if (MatgenGlobal.AuthUser.hasRole('admin')) {
      $('#template-manage').show();
      $('#microsite-manage').show();
      //$('#user-manage').show();
    } else {
      $('#template-manage').hide();
      $('#microsite-manage').hide();
      $('#user-manage').hide();
    }
  }
};

export const loginModal = () => {
  showModal({
    id: `matgen-signin-modal`,
    width: '550px',
    title: `<span style="color:#000000;font-weight:700;">LOG </span><span style="color:#255DFF;font-weight:700;">IN</span>`,
    content: loginModalContent,
    //noFrame: true,
    //header: false,
    footer: false,
  });
};

export const requireAuthModal = () => {
  showModal({
    id: `require-auth-modal`,
    content: `
      <div class="save-notice__container card card--basic">
        <h2 class="save-notice__heading">Before you continue…</h2>
        <div class="save-notice__copy">You’ll need an account. Please login or create one.</div>
        <div class="save-notice__buttons">
          <a href="#" id="require-auth-login" class="login-modal-link button--blue">Log In</a>
          <a href="#" id="require-auth-signup" class="signup-modal-link button--blue" style="margin-left:.5em;">Create account</a>
        </div>
        <a href="#" id="require-auth-cancel" data-bs-toggle="modal" data-bs-target="#require-auth-modal"
          class="link-primary">Cancel</a>
      </div>
    </div>
    `,
    noFrame: true,
    header: false,
    footer: false,
    bodyClose: true,
  });
};

export const signupModal = () => {
  showModal({
    id: `m4c-signup-modal`,
    width: '550px',
    title: `<span style="color:#000000;font-weight:700;">SIGN </span><span style="color:#255DFF;font-weight:700;">UP</span>`,
    content: `
        <form id="m4c-signup-form" class="modal-form" aria-label="Sign up">

          <label for="m4c-signup-email" class="sr-only">Email address</label>
          <input type="email" id="m4c-signup-email" class="form-control top" placeholder="Email address" required
            autofocus>
          <label for="m4c-signup-email-confirm" class="sr-only">Confirm Email address</label>
          <input type="email" id="m4c-signup-email-confirm" class="form-control middle" placeholder="Confirm Email address"
            required="" autofocus="">

          <div class="signup-form__password-rules">Password must be at least 8 characters long and contain at least one number,
            symbol, and uppercase letter.</div>

          <label for="m4c-signup-password" class="sr-only">Password</label>
          <input type="password" id="m4c-signup-password" class="form-control middle" placeholder="Password" required=>

          <label for="m4c-signup-password-confirm" class="sr-only">Confirm Password</label>
          <input type="password" id="m4c-signup-password-confirm" class="form-control bottom" placeholder="Confirm Password"
            required>

        </form>
    `,
    buttons: [
      {
        id: 'm4c-signup-submit',
        classname: 'primary btn btn-primary',
        label: 'Sign up',
      },
    ],
  });
};

export const forgotPasswordModal = () => {
  showModal({
    id: `forgot-password-modal`,
    title: 'Reset Password',
    content: `
      <form id="matgen-forgot-password-form" class="modal-form" aria-label="Reset password" style="max-width: 450px;">

        <p>Enter your username/email for a confirmation code to change your password. <b class="text-danger">Please ensure the email address you enter is correct. For security reasons, this form will not report if an email address is invalid.</b></p>
        <label for="forgot-password-email">Email Address</label>
        <input type="email" id="forgot-password-email" name="forgot-password-email" class="form-control" placeholder="Email Address" required autofocus>

      </form>
  `,
    //noFrame: true,
    //header: false,
    //footer: false,
    //bodyClose: true,
    buttons: [
      {
        id: 'reset-password-link',
        classname: 'secondary btn btn-secondary',
        label: 'Already Have Code',
      },
      {
        id: 'matgen-forgot-password-submit',
        classname: 'primary btn btn-primary',
        label: 'Send password reset code',
      },
    ],
  });
};

export const resetPasswordModal = () => {
  showModal({
    id: `reset-password-modal`,
    width: '550px',
    title: `Reset password`,
    content: `
      <form id="confirm-password-form" class="modal-form" aria-label="Reset password" style="max-width: 450px;"
      data-gtm-form-interact-id="1">
        <p><b>Note:</b> Password must be at least 8 characters in length, and include at least one uppercase letter, one
          lowercase letter, one number, and one special character.</p>
        <label for="confirm-password-email">Email Address</label>
        <input type="email" id="confirm-password-email" name="confirm-password-email" class="form-control top"
          placeholder="Email Address" required autofocus>

        <label for="confirm-password-password">Password</label>
        <input type="password" id="confirm-password-password" name="confirm-password-password"
          class="form-control middle pwcheck" placeholder="Password" required>

        <label for="confirm-password-password2">Confirm Password</label>
        <input type="password" id="confirm-password-password2" name="confirm-password-password2" class="form-control middle"
          placeholder="Confirm Password" required>

        <label for="inputConfirmationCode">Confirmation Code</label>
        <input type="text" id="inputConfirmationCode" name="inputConfirmationCode" class="form-control bottom"
          placeholder="Confirmation Code" required="">
      </form>
    `,
    buttons: [
      {
        id: 'matgen-confirm-password-submit',
        classname: 'primary btn btn-primary',
        label: 'Reset password',
      },
    ],
  });
};
